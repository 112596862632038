import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// mui
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';

// app
import { Button, ErrorMessage, LocationAutocomplete, Pagination } from 'components';

import * as utils from 'utils';

import { RiskRowItem } from './RiskRowItem';

const useStyles = makeStyles((theme) => ({
  buttonAdding: {
    paddingLeft: '30px!important',
  },
  buttonProgress: {
    position: 'absolute',
    top: 15,
    left: 20,
  },
}));

const useStylesCards = makeStyles((theme) => ({
  errorItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    marginRight: theme.spacing(1),
  },
  errors: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const RenderObjectCards = ({
  fields,
  removeHandler,
  copyHandler,
  formProps,
  definitionsFields,
  showIncompleteOnly,
  hasError,
  display,
  errors,
}) => {
  const classes = useStylesCards();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  useEffect(() => {
    const filteredFields = showIncompleteOnly ? fields?.filter((_, index) => hasError(index)) : fields;
    const displayFields = filteredFields?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? null;

    if (displayFields?.length === 0) {
      setPage(0);
    }
  }, [rowsPerPage, showIncompleteOnly]);

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const filteredFields = showIncompleteOnly ? fields?.filter((_, index) => hasError(index)) : fields;

  const displayFields = filteredFields?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? [];

  return (
    <>
      {display === 'MULTICARD' && fields?.length > 1 && (
        <Box
          display="flex"
          justifyContent="justify-between"
          alignItems="center"
          flex={1}
          style={{
            flex: 1,
            width: '100%',
          }}
        >
          <Box display="flex" justifyContent="flex-end" flex={1}>
            <Pagination
              page={page}
              count={filteredFields?.length ?? 0}
              rowsPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[9, 15, 30]}
            />
          </Box>
        </Box>
      )}
      <Grid container spacing={3} data-testid="grid-container-object-cards">
        {displayFields.map((item) => (
          <RiskRowItem
            key={item.id || `${item?.streetAddress}`}
            item={item}
            index={fields?.indexOf(item)}
            showIncompleteOnly={showIncompleteOnly}
            hasError={hasError}
            removeHandler={removeHandler}
            copyHandler={copyHandler}
            formProps={formProps}
            definitionsFields={definitionsFields}
          />
        ))}
      </Grid>
      {errors && errors.length > 0 && (
        <Box mt={2} className={classes.errors}>
          {errors.map((error, index) => {
            if (!error) {
              return null;
            }

            const pageNumber = Math.floor(index / rowsPerPage);

            const handleGoToPage = () => {
              if (pageNumber !== page) {
                setPage(pageNumber);
              }
            };

            return (
              <Box className={classes.errorItem}>
                <Typography className={classes.error} key={index} variant="body2">
                  Error in building {index + 1}
                </Typography>
                <Button color="primary" size="xsmall" variant="outlined" text={`Go to page ${pageNumber + 1}`} onClick={handleGoToPage} />
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};

export const AddRiskRowMultipleView = ({
  isAdding,
  field,
  formProps,
  showIncompleteOnly,
  handlers,
  errors,
  fields,
  showDownloadExcelTemplate,
}) => {
  const classes = useStyles();
  const checkError = (index) => {
    const visibleDefs = field.arrayItemDef.filter((f) => f.type !== 'hidden');
    const hasErrors = visibleDefs.some((def) => {
      const isFieldInValid =
        errors && errors[field.name] && errors[field.name][index] && utils.generic.isValidObject(errors[field.name][index], def.name);
      return isFieldInValid;
    });
    return hasErrors;
  };

  const hasErrors = fields?.some((_, index) => checkError(index));

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <LocationAutocomplete
          title={field?.label ? field?.label : "utils.string.t('products.multiLocation.label')"}
          buttonLabel={utils.string.t('app.addLocation')}
          textFieldPlaceholder={utils.string.t('products.multiLocation.placeholder')}
          handleAdd={handlers.handleAddNewObj}
          componentRestrictions={field?.componentRestrictions}
          getDTC
          isDisabled={field.display === 'SINGLECARD' && fields?.length === 1}
        />
        <Box position="relative" display="flex">
          <Box ml={1.5} mt={2}>
            <Button
              color="primary"
              size="small"
              variant="outlined"
              icon={isAdding ? null : FileCopyIcon}
              text={isAdding ? utils.string.t('app.addingLocations') : utils.string.t('app.pasteFromExcel')}
              disabled={isAdding}
              nestedClasses={isAdding ? { btn: classes.buttonAdding } : {}}
              onClick={() =>
                handlers.launchPasteFromExcelModal({
                  name: field.name,
                  headers: field.arrayItemDef.reduce(
                    (acc, def) => (def.type === 'hidden' ? [...acc] : [...acc, { key: def.name, value: '' }]),
                    []
                  ),
                  handlers: {
                    submit: (data) => {
                      handlers.appendHandler(data, true);
                      handlers.closePasteFromExcelModal();
                    },
                  },
                })
              }
            />
          </Box>
          {showDownloadExcelTemplate ? (
            <Box ml={1.5} mt={2}>
              <Button
                color="secondary"
                size="small"
                variant="outlined"
                icon={GetAppIcon}
                text={utils.string.t('app.downloadExcelTemplate')}
                onClick={() => handlers.handleDownloadExcelTemplate()}
              />
            </Box>
          ) : null}
          {isAdding ? <CircularProgress color="primary" size={16} className={classes.buttonProgress} /> : null}
        </Box>
      </Box>

      {errors[field?.name]?.type === 'required' ? (
        <Box mb={2}>
          <ErrorMessage size="lg" hint={errors[field?.name].message} error={{}} />
        </Box>
      ) : null}

      <Box>
        <RenderObjectCards
          fields={fields}
          removeHandler={handlers.removeHandler}
          copyHandler={handlers.copyHandler}
          formProps={formProps}
          definitionsFields={field}
          showIncompleteOnly={showIncompleteOnly}
          toggleIncompleteOnly={handlers.toggleIncompleteOnly}
          hasErrors={hasErrors}
          hasError={checkError}
          display={field.display}
          errors={errors[field.name]}
        />
      </Box>
    </>
  );
};

AddRiskRowMultipleView.propTypes = {
  field: PropTypes.object.isRequired,
  cols: PropTypes.array.isRequired,
  validFields: PropTypes.array.isRequired,
  formProps: PropTypes.object.isRequired,
  overflow: PropTypes.bool,
  formatData: PropTypes.string,
  handlers: PropTypes.shape({
    launchPasteFromExcelModal: PropTypes.func.isRequired,
    closePasteFromExcelModal: PropTypes.func.isRequired,
    toggleIncompleteOnly: PropTypes.func,
    handleDownloadExcelTemplate: PropTypes.func,
    copyHandler: PropTypes.func,
    removeHandler: PropTypes.func,
    appendHandler: PropTypes.func,
  }),
};
