import { useState } from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';

// Edge
import { Button } from 'components';

import { useGetEndorsementRiskData } from 'lib/quoteBind';
import * as utils from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {},
  typography: {
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'left',
    padding: theme.spacing(1),
    paddingLeft: 0,
  },
}));

export const EffectiveDateSelection = ({
  open,
  anchorEl,
  endorsementId,
  hasBackdateEndorsement,
  latestEffectiveEndorsementDate,
  policy,
  handleAddEndorsement,
  handleClose,
}) => {
  const classes = useStyles();
  const [effectiveDate, setEffectiveDate] = useState(null);
  const { isFetching: isLoading, refetch } = useGetEndorsementRiskData(policy?.riskId, effectiveDate);
  const { effectiveFrom, effectiveTo } = { ...policy?.response };
  let maxDate = null;
  const minDate = hasBackdateEndorsement ? utils.date.startOf(effectiveFrom) : utils.date.today();
  const earliestBindPeriod = policy?.facility?.earliestBindPeriod;

  if (latestEffectiveEndorsementDate) {
    maxDate = latestEffectiveEndorsementDate;
  } else {
    maxDate =
      utils.date.diffDays(utils.date.today(), effectiveTo) > earliestBindPeriod
        ? effectiveTo
        : utils.date.addDays(utils.date.today(), earliestBindPeriod);
  }

  const handleEffectiveDateChange = (date) => {
    setEffectiveDate(date);
  };

  const handleAddEndorsementClick = async () => {
    const result = await refetch();
    if (result.isSuccess) {
      const riskData = result.data;
      handleAddEndorsement(endorsementId || riskData, effectiveDate);
      handleClose();
    }
  };

  const handleCloseClick = () => {
    setEffectiveDate(null);
    handleClose();
  };

  const isValid = (effectiveDate, minDate, maxDate) =>
    utils.date.isValid(effectiveDate) && utils.date.isBetween(effectiveDate, minDate, utils.date.addDays(maxDate, 1));

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom"
      disablePortal={false}
      modifiers={{
        flip: {
          enabled: true,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent',
        },
        arrow: {
          enabled: false,
        },
      }}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper elevation={2}>
            <Box p={2}>
              <Typography className={classes.typography}>{utils.string.t('risks.endorsementFrom')}</Typography>
              <KeyboardDatePicker
                onChange={(date) => handleEffectiveDateChange(date)}
                autoOk
                placeholder={utils.date.today('DD-MM-yyyy')}
                value={effectiveDate}
                minDate={minDate}
                maxDate={maxDate}
                format="DD-MM-yyyy"
              />
            </Box>
            <Divider />
            <Box p={1} display="flex" justifyContent="flex-end">
              <Button variant="text" size="xsmall" onClick={handleCloseClick} text={utils.string.t('app.cancel')} />
              <Button
                variant="contained"
                disabled={!isValid(effectiveDate, minDate, maxDate) || isLoading}
                size="xsmall"
                color="primary"
                onClick={handleAddEndorsementClick}
                text={isLoading ? `Loading` : endorsementId ? utils.string.t('app.reQuote') : utils.string.t('app.add')}
                style={{
                  marginLeft: 10,
                }}
              />
            </Box>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};
